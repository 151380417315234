import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { ReactLenis } from '@studio-freight/react-lenis'
import { rgba } from 'emotion-rgba'
import React, { memo, Suspense, useEffect, useState } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight1: '#FFFFFF',
      neutralLight2: '#FCFBF0',
      neutralLight3: '#F5F4E7',
      neutralLight4: '#E8E7D8',
      neutralDark1: '#000000',
      primaryDark: '#07272D',
      primaryLight: '#779FA1',
      danger: '#F23030',
    },
  },
  fontFamily: {
    heading: "'futura-pt', sans-serif",
    paragraph: "'inter-variable', sans-serif",
  },
}

export type Styles = typeof styles

const titleStyle = css`
  color: ${theme.colors.variants.primaryDark};
  font-family: ${theme.fontFamily.heading};
  font-weight: 300;
  text-transform: uppercase;
`

export const styles = {
  label: css`
    color: ${theme.colors.variants.primaryDark};
    font-family: ${theme.fontFamily.heading};
    font-weight: 400;
    font-size: 0.875rem;
    letter-spacing: 0.05em;
    line-height: 1rem;
    margin: 0 auto 2rem;
    text-transform: uppercase;

    @media (max-width: 1199px) {
      margin-bottom: 1.125rem;
    }
  `,
  title: {
    xl: css`
      ${titleStyle}
      font-size: 9rem;
      line-height: 9rem;

      @media (max-width: 1199px) {
        font-size: 3.75rem;
        line-height: 3.75rem;
      }
    `,
    large: css`
      ${titleStyle}
      font-size: 5.25rem;
      line-height: 5.25rem;

      @media (max-width: 1199px) {
        font-size: 2.5rem;
        line-height: 2.5rem;
      }
    `,
    xm: css`
      ${titleStyle}
      font-size: 4.5rem;
      line-height: 4.5rem;

      @media (max-width: 1199px) {
        font-size: 2.625rem;
        line-height: 2.625rem;
      }
    `,
    xxm: css`
      ${titleStyle}
      font-size: 3.25rem;
      line-height: 4.25rem;
      text-transform: none;

      @media (max-width: 1199px) {
        font-size: 1.75rem;
        line-height: 2.375rem;
      }
    `,
    medium: css`
      ${titleStyle}
      font-size: 2.75rem;
      line-height: 2.75rem;

      @media (max-width: 1199px) {
        font-size: 1.75rem;
        line-height: 1.75rem;
      }
    `,
    small: css`
      ${titleStyle}
      font-size: 1.75rem;
      line-height: 1.75rem;

      @media (max-width: 1199px) {
        font-size: 1.375rem;
        line-height: 1.375rem;
      }
    `,
    xs: css`
      ${titleStyle}
      font-size: 1.5rem;
      line-height: 1.75rem;
      text-transform: none;
    `,
  },
  claim: css`
    ${titleStyle}
    font-size: 3.25rem;
    font-weight: 300;
    line-height: 4.25rem;
    text-transform: none;

    @media (max-width: 1199px) {
      font-size: 1.75rem;
      line-height: 2.375rem;
    }
  `,
  description: css`
    color: ${theme.colors.variants.primaryDark};
    font-family: ${theme.fontFamily.paragraph};
    font-variation-settings: 'wght' 300;
    font-size: 1rem;
    line-height: 1.625rem;
  `,
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

export type Datepicker = typeof datepicker

export const datepicker = css`
  .react-datepicker {
    min-width: 20rem;
    font-family: ${theme.fontFamily.paragraph};
    background-color: ${theme.colors.variants.primaryDark};
    color: ${theme.colors.variants.neutralLight1};
  }

  .react-datepicker__portal {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${rgba(theme.colors.variants.neutralLight1, 0.8)};
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .react-datepicker__current-month {
    font-family: ${theme.fontFamily.paragraph};
    color: ${theme.colors.variants.neutralLight1};
    font-variation-settings: 'wght' 600;
  }

  .react-datepicker__day-name {
    font-family: ${theme.fontFamily.paragraph};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--selected {
    border-color: ${theme.colors.variants.primaryLight};
    background-color: ${theme.colors.variants.primaryLight} !important;
    color: ${theme.colors.variants.neutralLight1} !important;
  }

  .react-datepicker__day:hover,
  .react-datepicker__day:focus,
  .react-datepicker__day:focus-visible {
    background-color: ${theme.colors.variants.primaryLight};
    color: ${theme.colors.variants.neutralLight1};
  }

  .react-datepicker__day--disabled {
    opacity: 0.3;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    input {
      background-color: transparent;
      border-color: ${theme.colors.variants.primaryDark};
      color: ${theme.colors.variants.primaryDark};
      font-family: ${theme.fontFamily.paragraph};
      &.error {
        border-color: ${theme.colors.variants.danger};
      }

      &::placeholder {
        color: ${theme.colors.variants.primaryDark};
        opacity: 0.3;
      }
      &:focus::placeholder {
        opacity: 1;
      }
    }
  }
`

interface Props {
  children: any
  fallback?: any
  pageContext: any
}

export const ThemeProvider = memo(function ThemeProvider({
  children,
  fallback,
}: Props) {
  const isBrowser = typeof window !== 'undefined'

  let smoothScroll = false
  if (isBrowser) smoothScroll = window.innerWidth > 1199 ? true : false

  const [isMounted, setMounted] = useState<boolean>(false)

  useEffect(() => {
    if (!isMounted) {
      setMounted(true)
    }
  }, [isBrowser])

  return (
    <EmotionThemeProvider theme={theme}>
      <Suspense fallback={fallback}>
        {!isMounted ? (
          fallback
        ) : (
          <>
            <style type="text/css">{mediaStyle}</style>
            <MediaContextProvider>
              {smoothScroll ? (
                <ReactLenis root options={{ duration: 1.4 }}>
                  {children}
                </ReactLenis>
              ) : (
                children
              )}
            </MediaContextProvider>
          </>
        )}
      </Suspense>
    </EmotionThemeProvider>
  )
})
